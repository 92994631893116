import React from 'react';
import { graphql, PageProps, Link } from 'gatsby';

import { BlogPostFull, Taxonomy } from '../model';
import { PageLayout } from '../components/PageLayout';
import { removeHTMLTags } from '../utils';
import { TaxonomyList } from '../components/TaxonomyList';

interface DataProps {
  allBlogPostsJson: {
    nodes: ReadonlyArray<BlogPostFull>;
  };
  allTagsJson: {
    nodes: ReadonlyArray<Taxonomy>;
  };
  allCategoriesJson: {
    nodes: ReadonlyArray<Taxonomy>;
  };
}

const BlogPost = ({ data }: PageProps<DataProps>) => {
  if (data.allBlogPostsJson.nodes.length === 0) {
    return null;
  }

  const node = data.allBlogPostsJson.nodes[0];

  return (
    <PageLayout
      title={removeHTMLTags(node.question).substring(0, 40)}
      description={removeHTMLTags(node.answer).substring(0, 140)}
    >
      <article key={node.jsonId} className="prose mx-auto">
        <h1 className="text-lg md:text-xl font-semibold">
          {removeHTMLTags(node.question)}
        </h1>
        <TaxonomyList
          title="Genres"
          taxonomies={node.meta.categories.map(
            (categoryId) =>
              data.allCategoriesJson.nodes.find((c) => c.jsonId === categoryId)!
          )}
        />
        <TaxonomyList
          title="Tags"
          taxonomies={node.meta.tags.map(
            (tagId) =>
              data.allTagsJson.nodes.find((node) => node.jsonId === tagId)!
          )}
        />
        <div
          className="mt-6"
          dangerouslySetInnerHTML={{ __html: node.answer }}
        ></div>
      </article>
    </PageLayout>
  );
};

export const query = graphql`
  query BlogPostQuery($id: String!) {
    allBlogPostsJson(filter: { id: { eq: $id } }) {
      nodes {
        ...BlogPostFullFragment
      }
    }
    allTagsJson {
      nodes {
        ...TagsJsonFragment
      }
    }
    allCategoriesJson {
      nodes {
        ...CategoriesJsonFragment
      }
    }
  }
`;

export default BlogPost;
