import React from 'react';
import { Link } from 'gatsby';

import { Taxonomy } from '../model';

interface TaxonomyListProps {
  title: string;
  taxonomies: ReadonlyArray<Taxonomy | undefined>;
  align?: 'left' | 'center';
}

export const TaxonomyList = ({
  title,
  taxonomies,
  align = 'left',
}: TaxonomyListProps) => {
  if (taxonomies.length === 0) {
    return null;
  }

  return (
    <div className={`${align === 'center' ? 'text-center' : ''}`}>
      <h4>{title}</h4>
      <ul
        className={`flex flex-wrap list-none p-0 gap-2 ${
          align === 'center' ? 'justify-center' : ''
        }`}
      >
        {taxonomies.map((term) =>
          term ? (
            <li key={term.jsonId} className="p-0 m-0 leading-none">
              <Link
                to={term?.fields.slug!}
                className="underline decoration-2 decoration-pink hover:text-pink"
              >
                {term?.name}
              </Link>
            </li>
          ) : null
        )}
      </ul>
    </div>
  );
};
